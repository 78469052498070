import React from 'react';
import { PageProps } from 'gatsby';

import Layout from '@/components/others/Layout';
import FirstView from '@/components/common/section/FirstView';
import Form from '@/components/contact/section/Form';
import PhoneContact from '@/components/contact/section/PhoneContact';
import Notice from '@/components/contact/section/Notice';
import FormBackDrop from '@/components/contact/middle/FormBackDrop';
import BottomMenu from '@/components/common/section/BottomMenu';
import StaticSEO from '@/components/common/section/StaticSEO';

const Contact: React.VFC<PageProps> = () => (
  <Layout>
    <StaticSEO
      titleData="お問い合わせ"
      descriptionData="鈴木弘基税理士事務所へのお問い合わせはこちらのページから。"
      OgImageData="Contact"
    />
    <Notice />
    <FormBackDrop />
    <FirstView fileName="Contact" pageTitle="お問い合わせ" />
    <Form />
    <PhoneContact />
    <BottomMenu />
  </Layout>
);

export default Contact;
