import React, { useState } from 'react';
import Axios from 'axios';
import { useAtom } from 'jotai';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import {
  contactSuccessState,
  contactErrorState,
  backDropStatus,
} from '@/others/atom';
import FormKeys from '@/others/contactFormKeys';

import FormField from '../basic/FormField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formBox: {
      padding: `1rem 0`,
      background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 15%, ${theme.palette.background.default} 45%, ${theme.palette.background.default} 100%)`,
    },
    formPaper: {
      margin: `0 auto`,
      width: `90%`,
      maxWidth: `1200px`,
      padding: `calc(1rem + 1vw)`,
    },
    desc: {
      margin: `1rem auto`,
    },
    formButton: {
      margin: `1rem auto`,
    },
    brPoint: {
      display: `inline-block`,
    },
  }),
);

const Form: React.VFC = () => {
  const [email, setEmail] = useState(``);
  const [subject, setSubject] = useState(``);
  const [content, setContent] = useState(``);
  const [emailIsCorrect, setEmailIsCorrect] = useState(true);
  const [contentIsCorrect, setContentIsCorrect] = useState(true);
  const [, setSuccessIsOpen] = useAtom(contactSuccessState);
  const [, setErrorIsOpen] = useAtom(contactErrorState);
  const [, setBackDropStatus] = useAtom(backDropStatus);

  const handleSubmit = () => {
    setBackDropStatus(true);
    const submitParams = new FormData();

    submitParams.append(FormKeys.email, email);
    submitParams.append(FormKeys.subject, subject);
    submitParams.append(FormKeys.content, content);

    Axios.post(FormKeys.corsProxy + FormKeys.action, submitParams)
      .then((response) => {
        console.log(response);
        setEmail(``);
        setSubject(``);
        setContent(``);
        setErrorIsOpen(false);
        setSuccessIsOpen(true);
        setBackDropStatus(false);
      })
      .catch((error) => {
        console.log(error);
        setSuccessIsOpen(false);
        setErrorIsOpen(true);
        setBackDropStatus(false);
      });
  };

  const classes = useStyles();

  return (
    <section className={classes.formBox}>
      <Paper elevation={4} className={classes.formPaper}>
        <Typography align="center" className={classes.desc}>
          <span className={classes.brPoint}>私たちは、税務や法律に関する</span>
          <span className={classes.brPoint}>ご相談、お問い合わせを</span>
          <span className={classes.brPoint}>いつでも受け付けております。</span>
          <br />
          <span className={classes.brPoint}>
            ご不明な点などがありましたら、
          </span>
          <span className={classes.brPoint}>お気軽にご連絡ください。</span>
        </Typography>
        <form>
          <FormField
            name="email"
            label="メールアドレス（必須）"
            type="email"
            setFieldState={setEmail}
            isCorrect={emailIsCorrect}
            setIsCorrect={setEmailIsCorrect}
          />
          <FormField
            name="subject"
            label="件名"
            setFieldState={setSubject}
            isCorrect
          />
          <FormField
            name="content"
            label="お問い合わせ内容（必須）"
            setFieldState={setContent}
            isCorrect={contentIsCorrect}
            setIsCorrect={setContentIsCorrect}
            rows={6}
          />
          <Button
            variant="contained"
            fullWidth
            size="large"
            color="primary"
            onClick={handleSubmit}
            className={classes.formButton}
            disabled={
              !(
                email !== `` &&
                emailIsCorrect &&
                content !== `` &&
                contentIsCorrect
              )
            }
          >
            送信
          </Button>
        </form>
      </Paper>
    </section>
  );
};

export default Form;
