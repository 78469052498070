/* eslint-disable no-irregular-whitespace */
import React from 'react';

import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import PhoneIcon from '@material-ui/icons/Phone';

const useStyles = makeStyles({
  phoneContactBox: {
    margin: `2rem auto`,
    width: `90%`,
    maxWidth: `1200px`,
  },
  phoneNumberBox: {
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    margin: `.1rem auto`,
    width: `12rem`,
  },
  phoneContactIcon: {
    display: `block`,
    margin: `.25rem`,
  },
  phoneNumber: {
    display: `block`,
    fontSize: `1.2rem`,
    margin: `0 auto`,
  },
  brAdjust: {
    display: `inline-block`,
  },
});

const PhoneContact: React.VFC = () => {
  const classes = useStyles();

  return (
    <section className={classes.phoneContactBox}>
      <Typography align="center">
        お電話でのお問い合わせはこちらの番号から
      </Typography>
      <div className={classes.phoneNumberBox}>
        <PhoneIcon className={classes.phoneContactIcon} />
        <Typography className={classes.phoneNumber}>049-251-4144</Typography>
      </div>
      <Typography align="center">営業時間：月〜金　09:00~18:00</Typography>
    </section>
  );
};

export default PhoneContact;
