/* eslint-disable no-unused-expressions */
import React from 'react';

import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';

type FormFieldProps = {
  name: 'email' | 'subject' | 'content';
  label: string;
  type?: string;
  setFieldState: React.Dispatch<React.SetStateAction<string>>;
  isCorrect: boolean;
  setIsCorrect?: React.Dispatch<React.SetStateAction<boolean>>;
  rows?: number;
};

const useStyles = makeStyles({
  formField: {
    margin: `1rem auto`,
  },
});

const FormField: React.VFC<FormFieldProps> = ({
  name,
  label,
  type,
  setFieldState,
  isCorrect,
  setIsCorrect,
  rows,
}) => {
  let errorMessage = ``;
  if (name === `email`) {
    errorMessage = `正しいメールアドレスを入力してください`;
  } else if (name === `content`) {
    errorMessage = `お問い合わせ内容は必須です`;
  }

  const handleChange = (value) => {
    setFieldState(value);
    if (name === `email`) {
      const regex =
        /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
      regex.test(value) ? setIsCorrect(true) : setIsCorrect(false);
    } else if (name === `content`) {
      value === `` ? setIsCorrect(false) : setIsCorrect(true);
    }
  };

  const classes = useStyles();

  return (
    <>
      <TextField
        variant="outlined"
        fullWidth
        multiline
        required={name === `email` || name === `content`}
        type={type || `text`}
        label={label}
        rows={rows || 1}
        onChange={(event) => handleChange(event.target.value)}
        className={classes.formField}
      />
      {!isCorrect && <FormHelperText error>{errorMessage}</FormHelperText>}
    </>
  );
};

export default FormField;
